import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);

const scrollAnimations = () => {
    let section = document.querySelectorAll('[data-animation-section]');
    if (section.length !== false && section) {
        section.forEach((currentSection) => {
            const delay = 0.2;
            let timeBlock = 0;

            setTimeout(() => {
                let elements = Array.from(currentSection.querySelectorAll('[data-animation-reveal]'));

                // Sort elements based on data-animation-order attribute
                elements.sort((a, b) => {
                    let orderA = a.getAttribute('data-animation-order') || 0;
                    let orderB = b.getAttribute('data-animation-order') || 0;
                    return orderA - orderB;
                });

                elements.forEach((elem) => {
                    let duration = 0.3;
                    let ease = 'power1';

                    ScrollTrigger.create({
                        trigger: currentSection,
                        start: getTriggerStart(),
                        once: true,
                        onEnter: function () {
                            if (elem.classList.contains('scroll-transition-active')) {
                                return;
                            }

                            elem.classList.add('scroll-transition-active');

                            if (elem.hasAttribute('data-animation-delay')) {
                                timeBlock = parseFloat(elem.getAttribute('data-animation-delay'));
                            }

                            if (elem.hasAttribute('data-animation-reveal-effect')) {
                                const effect = elem.getAttribute('data-animation-reveal-effect');

                                if (effect === 'bounce') {
                                    ease = getViewportWidth() < 1024 ? 'back.out(2)' : 'back.out(4)';
                                    duration = 0.5;
                                } else if (effect === 'fast') {
                                    ease = 'power4';
                                }
                            }

                            timeBlock = timeBlock + delay;

                            setAnimation(elem, duration, timeBlock, ease);
                        },
                    });
                });
            }, getTriggerTimer());
        });
    }

    function getViewportWidth() {
        return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    }

    function getTriggerStart() {
        let triggerStart = 'top 85%';
        if (getViewportWidth() < 1024) {
            triggerStart = 'top 100%';
        }
        return triggerStart;
    }

    function getTriggerTimer() {
        let triggerTimer = 250;
        if (getViewportWidth() < 1024) {
            triggerTimer = 150;
        }
        return triggerTimer;
    }

    function setAnimation(elem, duration, delay, ease) {
        gsap.to(elem, {
            duration: duration,
            delay: delay,
            x: 0,
            y: 0,
            scale: 1,
            autoAlpha: 1,
            webkitFilter: 'blur(0px)',
            filter: 'blur(0px)',
            ease: ease,
            overwrite: 'auto',
        });
    }

    //Nodig voor pagina's waar ajax wordt gebruikt
    document.addEventListener('htmx:afterSettle', function(event) {
        document.querySelectorAll('[data-animation-section]').forEach(function (element) {
            element.removeAttribute('data-animation-section');
        });
    });
};

export default scrollAnimations;