declare global {
    interface Window {
        onloadTurnstileCallback: () => void;
        turnstile: {
            render: (container, Object) => void;
        }
    }
}
export const inializeTurnStile = () => {
    // @ts-ignore
    const turnStileSiteKey = import.meta.env.VITE_TURNSTLE_SITE_KEY;
    const loadScripts = () => {
        const script = document.createElement('script');
        script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }

    const loadTurnStile = () => {
        //if no custom turnstile, do not load.

        console.debug('loading turnstile');
        //if turnstile not active, load it with the script, it could be possible it's already loaded by another plugin so check this.
        if (typeof window.turnstile === 'undefined') {
            loadScripts();
        }
        //initialize turnstile after script is loaded
        window.onloadTurnstileCallback = () => {
            window.turnstile.render('.ts-turnstile-custom-container', {
                sitekey: turnStileSiteKey,
                callback: (token) => {
                    //turnstile adds token automatic to form with input.
                    console.debug('Turnstile token received: ', token);
                }
            });
        }
    }
    //start the turnstile process
    loadTurnStile();
}
if (document.getElementsByClassName('ts-turnstile-custom-container').length) {
    inializeTurnStile();
}
